import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import ClassicContent from '../classic-content'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'

const BannerWrapper = styled.section`
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: hidden;

  ${up('lg')} {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  ${up('xl')} {
    padding-top: 200px;
    padding-bottom: 200px;
  }
`

const BannerImage = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% + 60px);
  left: 0;
  top: -30px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.25;
  }
`

const Banner = (data) => (
  <BannerWrapper data-scroll-section>
    <BannerImage>
      {data.image && <Img fixed={ data.image.sourceUrlSharp.childImageSharp.fixed } alt={ data.image.altText }/>}
    </BannerImage>
    <Container fluid>
      <Row>
        <Col sm={20} md={18} lg={14} xl={12} offset={{sm: 2, md: 3, lg: 5, xl: 6}}>
          <ClassicContent props={data} mode="light"/>
        </Col>
      </Row>
    </Container>
  </BannerWrapper>
)

export default Banner