import { css } from 'styled-components'

const GreenText = css`
  font-family: ${props => props.theme.fonts.headings};
  font-style: normal;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  color: ${props => props.theme.colors.green};
`

const StrokeText = css`
  body:not(.isMobile) & {
    @supports (-webkit-text-stroke: 1px ${props => props.theme.colors.green}) {
      color: transparent;
      -webkit-text-stroke: 1px ${props => props.theme.colors.green};
    }
  }
`

export default {
  GreenText,
  StrokeText
}