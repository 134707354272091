import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { useRelative } from '../hooks/useRelative'
import { up } from 'styled-breakpoints'
import Text from '../text'

const Title = styled(Text)`
  position: relative;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  padding: 20px 50px;
  display: inline-block;
  margin: 0 0 0 4.16vw;
  transform: translateY(-50%);
  z-index: 1;

  &::after {
    content: "";
    z-index: -1;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.green};
    transition: transform 1s ${props => props.theme.eases.out};
  }
`

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 350px;

  ${up('xxl')} { height: 500px; }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ${props => props.theme.eases.out} !important;
  }
`

const WrapperLink = styled(Link)`
  position: relative;
  display: inline-block;
  width: 100%;

  body:not(.isMobile) &:hover {
    ${Title}::after {
      transform: scale(1.2);
    }
    ${Image} {
      img { transform: scale(1.05); }
    }
  }

  ${up('md')} {
    margin: 50px 0;

    ${props => props.position ==='left' && `
      transform: translateY(-40%);
    `}
  }
`

const CardReference = (data) => (
  <WrapperLink to={useRelative(data.link)} position={data.position}>
    <Image>{data.featuredImage && <Img fixed={ data.featuredImage.node.sourceUrlSharp.childImageSharp.fixed } alt={ data.featuredImage.node.altText } />}</Image>
    <Title tag='h3' size='12'>{data.title}</Title>
  </WrapperLink>
)

export default CardReference