import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { up } from 'styled-breakpoints'
import Text from '../text'
import useRelative from '../hooks/useRelative'
import gsap from 'gsap'

const Suptitle = styled(Text)`
  color: black;
  opacity: 0.3;
  text-transform: uppercase;
  transition: color 0.4s ${props => props.theme.eases.out};
`

const Content = styled(Text)`
  margin-top: 15px;
  font-family: ${props => props.theme.fonts.headings};
  font-style: normal;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  line-height: 23px;
`

const Wrapper = styled(Link)`
  z-index: 2;
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: ${props => props.theme.colors.yellow};
  text-align: center;
  border-radius: 10px;
  transform: translate(0, -30px);
  padding: 15px;
  margin-top: 15px;
  transition: background-color 0.4s ${props => props.theme.eases.out};

  &:hover {
    background-color: ${props => props.theme.colors.green};
    ${Suptitle} { color: white; }
  }

  ${up('sm')} {
    padding: 40px;
    margin-top: 0;
  }

  ${up('lg')} { transform: translate(0, -60px); }
`

const YellowTag = (props) => {
  const $el = useRef(null)
  const link = useRelative(props.link.link)

  const checkScroll = (e) => {
    const currentScroll = e.detail.y
    const offset = 100

    gsap.to($el.current, {
      rotate: currentScroll / offset,
      duration: 0.4,
      ease: 'power2.out'
    })
  }

  useEffect(() => {
    window.addEventListener('customScrollEvent', checkScroll)

    return () => {
      window.removeEventListener('customScrollEvent', checkScroll)
    }
  }, [])

  return (
    <div ref={$el}>
      <Wrapper to={link} data-scroll data-scroll-speed='-1'>
          <Suptitle tag='div' size='12'>{props.suptitle}</Suptitle>
          <Content tag='div' size='18'>{props.content}</Content>
      </Wrapper>
    </div>
  )
}

export default YellowTag
