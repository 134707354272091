import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { useRelative } from '../hooks/useRelative'
import { Row, Col, Hidden, Visible } from 'react-grid-system'
import { up } from 'styled-breakpoints'
import Img from 'gatsby-image'
import Text from '../text'

const CardContent = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${props => props.theme.colors.green};
  padding: 30px;

  ${up('md')} {
    margin-top: 50px;
    transform: translateX(${props => props.align === 'right' && `-` }8.33vw);
    padding: 60px;
  }
`

const Category = styled(Text)`
  color: white;
  opacity: 0.3;
  text-transform: uppercase;
  margin-bottom: 30px;

  transition:
    transform 0.6s ${props => props.theme.eases.out} 0.13s,
    opacity 0.6s ${props => props.theme.eases.out} 0.13s;

    span {
      display: inline-block;
      margin-right: 10px;
    }
`

const Title = styled(Text)`
  color: white;
  text-transform: uppercase;

  transition:
    transform 0.6s ${props => props.theme.eases.out} 0.1s,
    opacity 0.6s ${props => props.theme.eases.out} 0.1s;
`

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  
  ${up('lg')} { height: 450px; }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s ${props => props.theme.eases.out} !important;
  }
`

const ReadMore = styled(Text)`  
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  span {
    z-index: 2;
    opacity: 0;
    transform: translateY(40px);
    transition:
      transform 0.6s ${props => props.theme.eases.out},
      opacity 0.1s ${props => props.theme.eases.out};
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.colors.greenLight};
    transform-origin: bottom center;
    transform: scaleY(0);
    transition: transform 0.6s ${props => props.theme.eases.out};
  }
`

const Card = styled(Link)`
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0 0 15px 0;

  ${up('md')} { margin: 35px 0; }
  
  body:not(.isMobile) &:hover {
    ${Category}, ${Title} {
      transform: translateY(-20px);
        opacity: 0;
        transition:
          transform 0.6s ${props => props.theme.eases.out},
          opacity 0.4s ${props => props.theme.eases.out};
    }

    ${ReadMore} {
      span {
        transform: translateY(0);
        opacity: 1;
        transition:
          transform 0.6s ${props => props.theme.eases.out},
          opacity 0.6s ${props => props.theme.eases.out} 0.1s;
      }

      &::after { transform: scaleY(1); }
    }

    ${Image} {
      img { transform: scale(1.05); }
    }
  }
`

const CardPost = (props) => (
  <Card to={useRelative(props.link)}>
    {props.index % 2 === 0 ?
      <Row>
        <Col md={12}><ImageMarkup {...props}/></Col>
        <Col md={12} lg={10}><div data-scroll data-scroll-speed="0.6"><CardContentMarkup {...props} align='right'/></div></Col>
      </Row>
    :
      <Row>
        <Hidden xs sm><Col md={12} lg={10} offset={{lg: 2}} data-scroll data-scroll-speed="0.6" style={{ zIndex: 1 }}><CardContentMarkup align='left' {...props}/></Col></Hidden>
        <Col md={12}><ImageMarkup {...props}/></Col>
        <Visible xs sm><Col md={12} lg={10} offset={{lg: 2}}><CardContentMarkup align='left' {...props}/></Col></Visible>
      </Row>
    }
  </Card>
)

const CardContentMarkup = (props) => (
  <CardContent align={props.align}>
    {props.categories &&<Category tag='div' size='12'>{props.categories.nodes.map((cat, index) => <span key={index}>{cat.name}</span>)}</Category>}
    <Title tag='div' size='18'>{props.title}</Title>
    <ReadMore tag='div' size='16'><span>Lire l'article</span></ReadMore>
  </CardContent>
)

const ImageMarkup = (props) => <Image>{props.featuredImage && <Img fixed={ props.featuredImage.node.sourceUrlSharp.childImageSharp.preview } alt={ props.featuredImage.node.altText } />}</Image>

export default CardPost