import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { useRelative } from '../hooks/useRelative'

const StyledLink = styled(Link)`
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  color: ${props => props.mode === 'light' ? props.theme.colors.green : props.theme.colors.yellowDark};
  text-transform: uppercase;
  padding: 20px 40px;
  transition: color 0.4s ${props => props.theme.eases.out};
  font-size: 12px;
  line-height: 15px;
  background-color: ${props => props.mode === 'light' ? 'white' : props.theme.colors.yellow};

  span {
    display: inline-block;
    height: 100%;
    transition:
      transform 0.6s ${props => props.theme.eases.out},
      opacity 0.4s ${props => props.theme.eases.out};
  }

  &::before {
    content: '${props => props.text}';
    color: ${props => props.mode === 'light' ? 'white' : props.theme.colors.yellow};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateY(30px);
    transition:
      transform 0.6s ${props => props.theme.eases.out},
      opacity 0.25s ${props => props.theme.eases.out};
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transform: scaleY(0);
    transition: transform 0.6s ${props => props.theme.eases.out};
  }

  &:hover {
    span {
      opacity: 0;
      transform: translateY(-30px);
      transition:
        transform 0.6s ${props => props.theme.eases.out},
        opacity 0.25s ${props => props.theme.eases.out};
    }

    &::before {
      transform: translateY(0);
      opacity: 1;
      transition:
        transform 0.6s ${props => props.theme.eases.out},
        opacity 0.4s ${props => props.theme.eases.out};
    }

    &::after { transform: scaleY(1); }
  }
`

const StyledTargetLink = styled.a`
  position: relative;
  overflow: hidden;
  z-index: 1;
  display: inline-block;
  color: ${props => props.mode === 'light' ? props.theme.colors.green : props.theme.colors.yellowDark};
  text-transform: uppercase;
  padding: 20px 40px;
  transition: color 0.4s ${props => props.theme.eases.out};
  font-size: 12px;
  line-height: 15px;
  background-color: ${props => props.mode === 'light' ? 'white' : props.theme.colors.yellow};

  span {
    display: inline-block;
    height: 100%;
    transition:
      transform 0.6s ${props => props.theme.eases.out},
      opacity 0.4s ${props => props.theme.eases.out};
  }

  &::before {
    content: '${props => props.text}';
    color: ${props => props.mode === 'light' ? 'white' : props.theme.colors.yellow};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: translateY(30px);
    transition:
      transform 0.6s ${props => props.theme.eases.out},
      opacity 0.25s ${props => props.theme.eases.out};
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    transform-origin: bottom center;
    transform: scaleY(0);
    transition: transform 0.6s ${props => props.theme.eases.out};
  }

  &:hover {
    span {
      opacity: 0;
      transform: translateY(-30px);
      transition:
        transform 0.6s ${props => props.theme.eases.out},
        opacity 0.25s ${props => props.theme.eases.out};
    }

    &::before {
      transform: translateY(0);
      opacity: 1;
      transition:
        transform 0.6s ${props => props.theme.eases.out},
        opacity 0.4s ${props => props.theme.eases.out};
    }

    &::after { transform: scaleY(1); }
  }
`

const Button = ({children, to, target, mode}) => {
  const mod = mode ? mode : 'normal'
  const link = useRelative(to)
  return target ? <StyledTargetLink mode={mod} href={link} text={children} target={target}><span>{children}</span></StyledTargetLink> : <StyledLink mode={mode} to={link} text={children}><span>{children}</span></StyledLink>
}

export default Button