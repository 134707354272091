import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { JsonLd } from './JsonLd'

function SEO({ data, description, lang, meta }) {
  const { wordpress } = useStaticQuery(
    graphql`
      query {
        wordpress {
          generalSettings {
            description
          }
          seo {
            schema {
              companyName
              companyOrPerson
              inLanguage
              siteName
              siteUrl
              wordpressSiteName
            }
          }
        }
      }
    `
  )

  const {
    title,
    metaDesc,
    twitterDescription,
    twitterTitle,
    metaRobotsNofollow,
    metaRobotsNoindex,
    opengraphDescription,
    opengraphTitle,
    opengraphUrl,
    opengraphSiteName,
    opengraphModifiedTime,
    opengraphPublishedTime
  } = data

  const {
    inLanguage,
    siteName,
    siteUrl
  } = wordpress.seo.schema

  const metaDescription = metaDesc || description || opengraphDescription

  return (
    <>
      <JsonLd>
        {{
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'WebSite',
              '@id': `${siteUrl}/#website`,
              'url': siteUrl,
              'name': siteName,
              'description': opengraphDescription || wordpress.generalSettings.description,
              'potentialAction': [{
                '@type': 'SearchAction',
                'target': `${siteUrl}/?s={search_term_string}`,
                'query-input': 'required name=search_term_string'
              }],
              'inLanguage': inLanguage
            },
            {
              '@type': 'WebPage',
              '@id': `${siteUrl}/#webpage`,
              'url': siteUrl,
              'name': siteName,
              'isPartOf': {
                '@id': `${siteUrl}/#website`
              },
              'datePublished': opengraphPublishedTime,
              'dateModified': opengraphModifiedTime,
              'inLanguage': inLanguage,
              'potentialAction': [{
                '@type': 'ReadAction',
                'target': [siteUrl]
              }]
            }]
        }}
      </JsonLd>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        meta={[
          {
            name: 'robots',
            content: `${metaRobotsNoindex}, ${metaRobotsNofollow}`
          },
          {
            name: `description`,
            content: metaDescription || wordpress.generalSettings.description,
          },
          {
            property: `og:title`,
            content: opengraphTitle,
          },
          {
            property: `og:url`,
            content: opengraphUrl,
          },
          {
            property: `og:description`,
            content: opengraphDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:site_name`,
            content: opengraphSiteName,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: twitterTitle || opengraphTitle || title,
          },
          {
            name: `twitter:description`,
            content: twitterDescription || metaDescription || opengraphDescription,
          },
        ].concat(meta)}
      />
    </>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
