import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { Container, Row, Col } from 'react-grid-system'
import gsap from 'gsap'
import Text from '../text'
import YellowTag from '../yellow-tag'

const CoverWrapper = styled.section`
  position: relative;
  display: inline-block;
  width: 100vw;

  ${up('lg')} { min-height: 100vh; }
`

const Image = styled.div`
  position: relative;
  z-index: 3;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Content = styled(Text)`
  margin: 30px 0;
`

const ContentWrapper = styled.div`
  opacity: 0;
`

const ImageWrapper = styled.div`
  position: relative;
  width: calc(100% + 30px);
  margin-left: -15px;

  ${up('lg')} {
    width: 100%;
    margin-left: 0;
  }
`

const Title = styled(Text)`
  position: absolute;
  left: 50%;
  top: 50%;
  color: ${props => props.theme.colors.green}; 
  z-index: 2;
  white-space: nowrap;
  transform: translate(-50vw, -50%);
  margin: 0;

  ${props => props.transform}

  & > div {
    display: block;
    transform: translateX(110vw);
    transition: transform 1s ${props => props.theme.eases.out};

    & > div {
      will-change: transform;
      animation: moveCoverRight 4s infinite linear;
      animation-play-state: ${props => props.state};

      &::after {
        white-space: nowrap;
        content: "${props => props.text}";
        color: inherit; 
      }
    }
  }
`

const TitleWhite = styled(Title)`
  font-family: ${props => props.theme.fonts.headings};
  font-style: normal;
  font-weight: bold;
  color: white;
`

const TitleWhiteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 4;
`

const YellowTagWrapper = styled.div`
  position: relative;
  z-index: 4;
  width: 100%;
  opacity: 0;
  transform: translateY(150px) rotate(15deg);
`

const Cover = (props) => {
  const image = useRef(false)
  const contentWrapper = useRef(false)
  const yellowTag = useRef(false)
  const textWrapper1 = useRef(false)
  const textWrapper2 = useRef(false)
  const imageElt = useRef(false)
  const [infiniteText, setInfiniteText] = useState('')
  const [transformInfiniteText, setTransformInfiniteText] = useState(1)
  const [stateAnimation, playAnimation] = useState('paused')

  const setDimensions = (isResize) => {
    requestAnimationFrame(() => {

      const textWidth = textWrapper1.current.getBoundingClientRect().width
      const windowRatio = window.innerHeight / window.innerWidth
      const imageWidth = image.current.parentElement.offsetWidth

      // INFINITE TEXT
      const textMultiplier = Math.ceil(window.innerWidth / textWidth) + 1
      
      let text = '';

      for (let i = 0; i < textMultiplier; i++) {
        text += ` `
        text += `${props.title}`
      }

      setInfiniteText(text)
      setTransformInfiniteText(`
      @keyframes moveCoverRight {
        0% { transform: translateX(0); }
        100% { transform: translateX(${-1 * (100/(textMultiplier + 1))}%); }
      }`
      )
      
      // IMAGE
      let imageHeight = null

      if (window.innerWidth > 1024) imageHeight = imageWidth * windowRatio
      else if (window.innerWidth >= 768) imageHeight = `500`
      else imageHeight = `300`

      const differenceImageWindowHeight = window.innerHeight - imageHeight

      if (window.innerWidth > 1024) image.current.style.marginTop = `${differenceImageWindowHeight / 2}px`
      else if (window.innerWidth >= 768) image.current.style.marginTop = `0`
      else image.current.style.marginTop = `80px`

      image.current.style.width = `${imageWidth}px`
      image.current.style.height = `${imageHeight}px`

      if (isResize !== false) return

      const scaleXFullWidth = window.innerWidth / imageWidth
      image.current.style.transform = `scale(${scaleXFullWidth})`
    })
  }

  const appear = () => {
    const tl = new gsap.timeline({
      onStart: () => { window.scroll && window.scroll.stop && window.scroll.stop() },
    })

    if (window.innerWidth > 768) {
      tl.to(image.current, {
        scale: 1,
        duration: 1,
        ease: 'power3.inOut'
      })
    }

    tl.to([textWrapper1.current, textWrapper2.current], {
      delay: window.innerWidth <= 768 ? 0.4 : 0,
      x: 0,
      duration: 1,
      ease: 'power3.in',
      onComplete: () => playAnimation('running')
    }, '-=0.7')

    tl.to(contentWrapper.current, {
      alpha: 1,
      duration: 1,
      ease: 'power3.out'
    }, '+=0.6')

    tl.to(yellowTag.current, {
      alpha: 1,
      y: 0,
      rotation: window.innerWidth >= 768 ? -7 : 0,
      duration: 1,
      ease: 'power3.out',
      onStart: () => { window.scroll && window.scroll.start && window.scroll.start() } 
    }, '-=1')
  }

  const loadImage = () => {
    return new Promise((resolve) => {
      if (imageElt.current.complete && imageElt.current.naturalHeight !== 0) resolve()
      else imageElt.current.addEventListener('load', resolve)
    })
  }

  useEffect(() => {
    !window.fromTransition && appear()
    setDimensions(false)

    window.currentCover = {
      loadImage,
      appear
    }

    window.addEventListener('resize', setDimensions)

    return () => {
      window.removeEventListener('resize', setDimensions)
    }
  }, [])
  
  return (
    <CoverWrapper data-scroll-section>
      <Container fluid>
        <Row nogutter>
          <Col lg={14} offset={{ lg: 5 }}>
            <ImageWrapper>
              {console.log(transformInfiniteText)}
              <Image ref={image}>{props.image && <img ref={imageElt} src={ props.image.sourceUrlSharp.childImageSharp.fixed.src } alt={ props.image.altText } />}</Image>
              <Title tag='h1' size="120" text={infiniteText} transform={transformInfiniteText} state={stateAnimation}><div ref={textWrapper1}><div>{props.title}</div></div></Title>
              <TitleWhiteWrapper><TitleWhite tag='div' size="120" transform={transformInfiniteText} text={infiniteText} state={stateAnimation}><div ref={textWrapper2}><div>{props.title}</div></div></TitleWhite></TitleWhiteWrapper>
            </ImageWrapper>
          </Col>
          {props.content && <Col sm={14} md={12} lg={8} offset={{ lg: 5 }}>
            <ContentWrapper ref={contentWrapper}>
              <Content tag='div' size='14' dangerouslySetInnerHTML={{__html: props.content}}/>
            </ContentWrapper>
          </Col>}
          {props.sticker && props.sticker.suptitle && <Col sm={8} md={6} lg={5} xxl={4} offset={{sm: 2, md: 6, lg: 3, xxl: 4}}>
            <YellowTagWrapper ref={yellowTag}>
              <YellowTag {... props.sticker}/>
            </YellowTagWrapper>
          </Col>}
        </Row>
      </Container>
    </CoverWrapper>
  )
}

export default Cover