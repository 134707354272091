import React from 'react'
import styled from 'styled-components'
import Button from '../button'
import { up } from 'styled-breakpoints'
import Text from '../text'

const Wrapper = styled.div`
  position: relative;

  ${up('sm')} { margin: 50px 0; }
`

const Suptitle = styled(Text)`
  position: relative;
  text-transform: uppercase;
  color: ${props => props.mode === 'light' ? 'white' : props.theme.colors.green };
  text-align: ${props => props.mode === 'light' ? 'center' : 'left'};

  ${props => props.mode === 'normal' && `
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
      height: 1px;
      background-color: ${props.theme.colors.green};
      width: 90px;
      display: none;
    }
  `}

  ${up('sm')} {
    ${props => props.mode === 'normal' && `
      padding-left: 110px;

      &::before {
        display: block;
      }`
    }
  }
`

const Title = styled(Text)`
  margin: 30px 0;
  color: ${props => props.mode === 'light' ? 'white' : props.theme.colors.green};
  text-align: ${props => props.mode === 'light' ? 'center' : 'left'};
`

const Content = styled.div`
  margin: 30px 0;
  text-align: ${props => props.mode === 'light' ? 'center' : 'left'};
`

const StyledButton = styled.div`
  margin: ${props => props.mode === 'light' ? '50px' : '30px'} 0;
  text-align: ${props => props.mode === 'light' ? 'center' : 'left'};
`

const ClassicContent = (data) => {
  const mode = data.mode ? data.mode : 'normal'

  return (
    <Wrapper>
      {data.props.suptitle && <Suptitle mode={mode} tag='div' size='12'>{data.props.suptitle}</Suptitle>}
      <Title mode={mode} tag='h2' size='40'>{data.props.title}</Title>
      <Content dangerouslySetInnerHTML={{__html: data.props.content}} mode={mode}/>
      {data.props.link && <StyledButton mode={mode}><Button mode={mode} target={data.props.link.target} to={data.props.link.url}>{data.props.link.title}</Button></StyledButton>}
    </Wrapper>
  )
}

export default ClassicContent